import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Listagem do bônus "),_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"warning"},on:{"click":function($event){return _vm.exportCsv()}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDownloadOutline)+" ")]),_vm._v(" Exportar ")],1)],1),_c(VDivider),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.dataTable,"loading":_vm.loading,"loading-text":"Carregando dados..."}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }