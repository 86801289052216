<template>
  <v-card>
    <v-card-title>
      Listagem do bônus
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="warning"
        @click="exportCsv()"
      >
        <v-icon>
          {{ icons.mdiDownloadOutline }}
        </v-icon>
        Exportar
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="dataTable"
        :loading="loading"
        loading-text="Carregando dados..."
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiDownloadOutline } from '@mdi/js'
import { saveAs } from 'file-saver'

const ExcelJS = require('exceljs')

export default {
  data() {
    return {
      icons: {
        mdiDownloadOutline,
      },
      dataTable: [],
      headers: [
        { text: 'TIPO DOCUMENTO', value: 'document_type', align: 'center' },
        { text: 'VALOR BRUTO', value: 'gross_value', align: 'center' },
        { text: 'NUMERO FATURA', value: 'invoice_number', align: 'center' },
        { text: 'DATA PAGAMENTO', value: 'payment_date', align: 'center' },
        { text: 'CODIGO IHS', value: 'region.code_ihs', align: 'center' },
        { text: 'REGIÃO', value: 'region.region', align: 'center' },
      ],
      loading: true,
    }
  },

  async created() {
    await this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      await axiosIns.get('api/v1/integration/commission/index')
        .then(res => {
          this.dataTable = res.data.data
        })
      this.loading = false
    },

    async exportCsv() {
      const file = await this.getCsv()
      if (file) {
        saveAs(file)
      }
    },

    async getCsv() {
      const { dataTable } = this

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')

      worksheet.columns = [
        { header: 'TIPO DOCUMENTO', key: 'tipo_documento' },
        { header: 'VALOR BRUTO', key: 'valor_bruto' },
        { header: 'NUMERO FATURA', key: 'numero_fatura' },
        { header: 'DATA PAGAMENTO', key: 'data_pagamento' },
        { header: 'CODIGO IHS', key: 'codigo_ihs' },
        { header: 'REGIÃO', key: 'regiao' },
      ]

      dataTable.map(item => {
        worksheet.addRow([
          item.document_type,
          item.gross_value,
          item.invoice_number,
          item.payment_date,
          item.region.code_ihs,
          item.region.region,
        ])
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'data_bonus', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })

      return blob
    },
  },
}
</script>
